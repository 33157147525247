import React from "react";
// import { Button } from "../../globalStyles";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  // FooterSubscription,
  FooterSubText,
  // FooterSubHeading,
  // FooterHead,
  FooterAddressText,
  // Form,
  // FormInput,
  FooterLinkItemsTop,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterObserveer,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  ObserveerIcon,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  // FooterSubTextTop,
} from "./Footer.elements";

function Footer({ img, imgObserveer, imgObserveerTwo }) {
  const date = new Date();

  return (
    <FooterContainer>
      <FooterLinksWrapper>
        <FooterObserveer>
          <SocialLogo to="/">
            <SocialIcon src={img} />
          </SocialLogo>
          <FooterAddressText></FooterAddressText>
        </FooterObserveer>

        <FooterObserveer>
          <FooterSubText>Telah terdaftar dan diawasi oleh:</FooterSubText>
          <FooterLinksWrapper>
            <FooterLinkItemsTop>
              {/* <ObserveerIcon src={imgObserveer} /> */}
            </FooterLinkItemsTop>
            <FooterLinkItemsTop>
              {/* <ObserveerIcon src={imgObserveerTwo} /> */}
            </FooterLinkItemsTop>
          </FooterLinksWrapper>
        </FooterObserveer>
      </FooterLinksWrapper>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Perusahaan</FooterLinkTitle>
            <FooterLink to="/">Tentang Kami</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Fitur</FooterLinkTitle>
            <FooterLink to="/">fitur</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Produk</FooterLinkTitle>
            <FooterLink to="/">produk</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Bahasa</FooterLinkTitle>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          {/* <SocialLogo to="/">
            <SocialIcon src={img} />
          </SocialLogo> */}
          <WebsiteRights>
            ©Copyright {date.getFullYear()} PT.AUP GRUP INDONESIA
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink href="/" target="_blank" aria-label="Facebook">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="YouTube">
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
