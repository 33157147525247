import React from "react";
import {
  About,
  InfoSession,
  InfoSection,
  CompanyFocus,
  CallToAction,
  // Pricing,
} from "../../components";
import {
  homeObjOne,
  homeAbout,
  homeFeature,
  homeSector,
  companyFocus,
  callToAction,
} from "./Data";

const Home = () => {
  return (
    <>
      <InfoSection {...homeObjOne} />
      <About {...homeAbout} />
      <InfoSession {...homeFeature} />
      <InfoSession {...homeSector} />
      <CompanyFocus {...companyFocus} />
      <CallToAction {...callToAction} />
    </>
  );
};

export default Home;
