import React from "react";
import {
  InfoSessionSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Heading,
  Subtitle,
  PointWrapper,
  PointContainer,
  PointIcon,
  PointTitle,
  PointSub,
  ImgWrapper,
  Img,
  Icon,
} from "./InfoSession.element";
import { Container } from "../../globalStyles";

const InfoSection = ({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
  start,
  icon,
  section,
}) => {
  return (
    <>
      <InfoSessionSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                <PointWrapper>
                  {section &&
                    typeof section === "object" &&
                    section.map((item, id) => {
                      return (
                        <PointContainer key={id}>
                          <PointIcon>
                            <Icon src={item.icon} alt={alt} />
                          </PointIcon>
                          <PointTitle>{item.title}</PointTitle>
                          <PointSub>{item.content}</PointSub>
                        </PointContainer>
                      );
                    })}
                </PointWrapper>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSessionSec>
    </>
  );
};

export default InfoSection;
