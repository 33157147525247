export const navbarLogo = {
  imgStart: "",
  img: require("../src/images/tranzferin.svg"),
};

export const footerLogo = {
  imgStart: "",
  img: require("../src/images/tranzferin-white.svg"),
  imgObserveer: require("../src/images/default.svg"),
  imgObserveerTwo: require("../src/images/default.svg"),
};
