import styled from "styled-components";

export const CompFoc = styled.section`
  position: relative;
  padding: 4rem 0 4rem;
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 414px) {
    padding: 4rem 1.5rem 4rem;
  }

  @media screen and (min-width: 415px) {
    padding: 4rem 7rem 4rem;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 650px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Heading = styled.h3`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#190b31" : "#1c2237")};

  @media only screen and (max-width: 767px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#343D48" : "#1c2237")};
`;

export const PointWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const PointContainer = styled.div`
  width: 250px;
  min-height: 100px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 20px;
  transition: all 0.2s;

  @media only screen and (max-width: 768px) {
    margin: auto;
  }
`;

export const PointIcon = styled.div`
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
`;

export const PointTitle = styled.h6`
  font-size: 1rem;
  line-height: 1rem;
  color: #190b31;
  font-weight: 600;
  margin-top: 30px;
`;

export const PointSub = styled.h6`
  font-size: 14px;
  line-height: 24px;
  color: #190b31;
  font-weight: 400;
  margin-top: 5px;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
