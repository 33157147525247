export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Tranzferin",
  headline: "Otomatisasi Perpajakan untuk Bisnis",
  description: "",
  buttonLabel: "Pakai Sekarang",
  imgStart: "",
  img: require("../../images/reporting.svg"),
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Instant Setup",
  headline: "Extremely quick onboarding process",
  description:
    "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/svg-2.svg"),
  alt: "Vault",
  start: "",
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Sarah Jeni",
  headline:
    "Ultra helped me increase my revenue by over 3X in less than 3 months!",
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.",
  buttonLabel: "View Case Study",
  imgStart: "start",
  img: require("../../images/profile.jpg"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Secure Database",
  headline: "All your data is stored on our secure server",
  description:
    "You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/svg-3.svg"),
  alt: "Vault",
  start: "true",
};

export const homeAbout = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline:
    "Tranzferin dimulai sebagai aplikasi transfer dana untuk menjadi sesuatu yang lebih besar",
  description: "",
  buttonLabel: "Tentang Kami",
  alt: "Credit Card",
};

export const homeFeature = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline: "Otomatis, Mudah, dan Praktis",
  description:
    "Otomasi administrasi pajak saat melakukan transaksi pembayaran dengan Tranzferin.id",
  buttonLabel: "Tentang Kami",
  imgStart: "start",
  img: require("../../images/graphs.svg"),
  alt: "Vault",
  start: "true",
  section: [
    {
      title: "Jenis dan Jumlah Pajak",
      content:
        "Tranzferin mengidentifikasi jenis pajak, menghitung secara otomatis jumlah pajak yang wajib dipotong dan mengurus pembayaran pajak tersebut.",
      icon: require("../../images/check.svg"),
    },
    {
      title: "SPT Pajak",
      content:
        "Tranzferin mengisi SPT Pajak dari transaksi yang dilakukan via aplikasi.",
      icon: require("../../images/check.svg"),
    },
    {
      title: "Bukti Potong",
      content:
        "Bukti potong dikirimkan langsung oleh Tranzferin kepada pihak lawan transaksi.",
      icon: require("../../images/check.svg"),
    },
    {
      title: "Laporan Keuangan",
      content:
        "Tranzferin menyediakan laporan keuangan bagi pengguna yang sudah cocok dengan data perpajakan.",
      icon: require("../../images/check.svg"),
    },
  ],
};

export const homeSector = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline: "Tranzferin siap membantu perusahaan di semua level",
  description: "",
  buttonLabel: "Tentang Kami",
  imgStart: "",
  img: require("../../images/collaborators.svg"),
  alt: "Perusahaan",
  start: "true",
  section: [
    {
      title: "Perusahaan Perorangan",
      content: "Usaha perorangan yang diwajibkan melakukan pemotongan pajak.",
      icon: require("../../images/corporate.svg"),
    },
    {
      title: "Perusahaan UKM",
      content:
        "Perusahaan kecil menengah yang ingin mendapatkan kepastian urusan pajak dan layanan pajak secara efisien.",
      icon: require("../../images/corporate-ukm.svg"),
    },
  ],
};

export const companyFocus = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline:
    "Kenyamanan dan keamanan pengguna adalah prioritas nomor satu untuk kami",
  description: "",
  buttonLabel: "Tentang Kami",
  imgStart: "start",
  img: require("../../images/graphs.svg"),
  alt: "Vault",
  start: "true",
  section: [
    {
      title: "Sepenuhnya Digital",
      content: "Pengguna dapat melakukan semua transaksi di mana saja.",
      icon: require("../../images/digital.svg"),
    },
    {
      title: "Mudah Digunakan",
      content:
        "Antarmuka aplikasi ini sederhana, mudah digunakan, dan ramah bagi pengguna maupun lawan transaksi.",
      icon: require("../../images/like.svg"),
    },
    {
      title: "Kepastian Pajak",
      content:
        "Pengguna mendapatkan kepastian administrasi pajak, mengurangi sanksi pajak.",
      icon: require("../../images/vat-tax.svg"),
    },
    {
      title: "Aman",
      content:
        "Kami memiliki fitur keamanan untuk melindungi transaksi dan data pengguna sesuai dengan standar keamanan jasa keuangan.",
      icon: require("../../images/secure.svg"),
    },
  ],
};

export const callToAction = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline: "Tranzferin memudahkan administrasi pajak bagi dunia usaha",
  description: "",
  buttonLabel: "Pakai Sekarang",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
  start: "",
};
