import React from "react";
import {
  CTASec,
  ContentWrapper,
  Heading,
  Subtitle,
} from "./CallToAction.element";
import { Button } from "../../globalStyles";
import { Link } from "react-router-dom";

const InfoSection = ({
  primary,
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
}) => {
  return (
    <>
      <CTASec>
        <ContentWrapper>
          <Heading lightText={lightText}>{headline}</Heading>
          <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
          <Link to="/">
            <Button big fontBig primary={primary}>
              {buttonLabel}
            </Button>
          </Link>
        </ContentWrapper>
      </CTASec>
    </>
  );
};

export default InfoSection;
