import React from "react";
import {
  CompFoc,
  ContentWrapper,
  Heading,
  Subtitle,
  PointWrapper,
  PointContainer,
  PointIcon,
  PointTitle,
  PointSub,
  Icon,
} from "./CompanyFocus.element";

const CompanyFocus = ({
  primary,
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  section,
  alt,
  imgStart,
  start,
}) => {
  return (
    <>
      <CompFoc>
        <ContentWrapper>
          <Heading lightText={lightText}>{headline}</Heading>
          <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
          <PointWrapper>
            {section &&
              typeof section === "object" &&
              section.map((item, id) => {
                return (
                  <PointContainer key={id}>
                    <PointIcon>
                      <Icon src={item.icon} alt={alt} />
                    </PointIcon>
                    <PointTitle>{item.title}</PointTitle>
                    <PointSub>{item.content}</PointSub>
                  </PointContainer>
                );
              })}
          </PointWrapper>
        </ContentWrapper>
      </CompFoc>
    </>
  );
};

export default CompanyFocus;
