import styled from "styled-components";

export const InfoSessionSec = styled.div`
  color: #fff;
  padding: 100px 0;
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;
  }
`;

export const PointWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const PointContainer = styled.div`
  width: 250px;
  min-height: 100px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 20px;
  transition: all 0.2s;

  @media only screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`;

export const PointIcon = styled.div`
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
`;

export const PointTitle = styled.h6`
  font-size: 1rem;
  line-height: 1rem;
  color: #190b31;
  font-weight: 600;
  margin-top: 30px;
`;

export const PointSub = styled.h6`
  font-size: 14px;
  line-height: 24px;
  color: #190b31;
  font-weight: 400;
  margin-top: 5px;
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

export const Heading = styled.h3`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#190b31" : "#1c2237")};

  @media only screen and (max-width: 767px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#343D48" : "#1c2237")};
`;
