import styled from "styled-components";

export const CTASec = styled.section`
  position: relative;
  padding: 7rem 0 7rem;
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    padding: 5.5rem 1.5rem 3rem;
  }

  @media screen and (min-width: 768px) {
    padding: 4rem 7rem 4rem;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 850px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Heading = styled.h3`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#190b31" : "#1c2237")};

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }

  @media screen and (min-width: 768px) {
    font-size: 36px;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#343D48" : "#1c2237")};
`;
