import React from "react";
import { AboutSec, ContentWrapper, Heading, Subtitle } from "./About.element";
import { Button } from "../../globalStyles";
import { Link } from "react-router-dom";

const InfoSection = ({
  primary,
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
}) => {
  return (
    <>
      <AboutSec>
        <ContentWrapper>
          <Heading lightText={lightText}>{headline}</Heading>
          <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
          <Link to="/">
            <Button big fontBig primary={primary}>
              {buttonLabel}
            </Button>
          </Link>
        </ContentWrapper>
      </AboutSec>
    </>
  );
};

export default InfoSection;
